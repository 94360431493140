import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 600.000000 599.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,599.000000) scale(0.100000,-0.100000)" >


<path d="M2680 5825 c-505 -57 -964 -285 -1331 -661 -234 -241 -397 -509 -505
-831 -56 -167 -79 -273 -99 -441 -67 -569 87 -1131 438 -1602 85 -114 293
-324 397 -401 183 -136 347 -229 530 -302 432 -172 879 -210 1295 -112 431
103 767 282 1059 563 289 280 484 605 595 990 50 175 68 277 81 462 18 252 -9
500 -79 740 -53 183 -78 246 -156 399 -254 500 -682 882 -1207 1076 -318 118
-668 159 -1018 120z m461 -75 c948 -96 1723 -840 1864 -1785 99 -667 -123
-1336 -599 -1810 -254 -252 -526 -414 -881 -524 -398 -123 -842 -120 -1240 7
-913 294 -1509 1141 -1472 2092 15 379 114 705 311 1030 87 143 158 232 301
376 196 198 368 318 632 441 174 82 429 152 618 172 118 12 348 13 466 1z"/>
<path d="M2654 5656 c-121 -18 -175 -30 -328 -78 -743 -230 -1290 -882 -1390
-1656 -130 -1010 506 -1954 1489 -2208 191 -49 313 -65 509 -64 306 0 577 60
851 189 233 110 374 210 565 401 237 237 396 492 496 798 224 682 60 1444
-425 1976 -296 325 -671 535 -1121 628 -124 25 -508 34 -646 14z m518 -921
c62 -13 147 -33 188 -45 41 -12 95 -21 120 -21 38 -1 49 4 73 31 15 17 35 30
45 28 18 -3 19 -17 36 -475 l7 -183 -31 0 c-26 0 -35 7 -58 47 -201 344 -384
518 -590 562 -194 41 -394 -37 -466 -182 -21 -40 -26 -66 -26 -122 0 -98 23
-159 80 -216 79 -79 195 -127 544 -224 332 -91 514 -211 605 -397 51 -105 70
-177 77 -303 8 -119 -6 -218 -45 -333 -40 -114 -83 -184 -171 -272 -87 -88
-211 -158 -337 -191 -165 -43 -400 -35 -676 22 -295 61 -290 61 -327 -11 -16
-30 -26 -40 -45 -40 -14 0 -25 1 -25 3 -5 65 -30 663 -30 710 l0 67 29 0 c27
0 34 -8 71 -78 126 -237 345 -468 530 -562 223 -112 504 -70 617 94 43 63 56
115 51 206 -7 103 -36 168 -106 230 -78 69 -166 110 -417 189 -326 104 -406
138 -518 223 -108 80 -174 177 -218 318 -21 66 -24 95 -24 235 0 130 3 170 19
215 116 340 408 523 803 504 56 -3 153 -16 215 -29z"/>
<path d="M666 875 c-62 -22 -91 -57 -91 -109 0 -62 30 -91 125 -120 71 -21 75
-24 75 -52 0 -28 -2 -29 -53 -32 -36 -2 -62 2 -78 12 -29 20 -34 20 -63 -5
-23 -19 -23 -20 -5 -40 31 -35 74 -51 133 -51 103 0 161 44 161 122 0 55 -24
86 -85 109 -31 12 -60 21 -65 21 -28 0 -61 25 -58 44 4 28 73 34 119 11 31
-17 32 -16 58 9 l26 26 -30 24 c-26 21 -96 47 -122 46 -4 -1 -26 -7 -47 -15z"/>
<path d="M2247 876 c-51 -19 -76 -42 -99 -94 -38 -85 -18 -205 44 -257 36 -30
100 -48 140 -40 21 5 35 2 48 -10 28 -26 55 -6 56 42 1 21 9 58 19 83 20 54
16 139 -11 195 -33 70 -124 107 -197 81z m122 -102 c16 -20 21 -41 21 -83 0
-64 -14 -96 -30 -71 -15 23 -66 -8 -62 -38 3 -23 -5 -27 -33 -16 -46 18 -63
158 -24 208 16 21 29 26 64 26 35 0 48 -5 64 -26z"/>
<path d="M3161 872 c-23 -18 -164 -369 -155 -383 6 -11 55 -12 70 -1 6 4 17
24 23 45 l13 37 72 0 73 0 19 -42 c16 -36 24 -43 52 -46 20 -2 34 2 37 10 6
14 -44 153 -107 302 -37 88 -62 108 -97 78z m53 -156 c24 -73 22 -76 -29 -76
-25 0 -45 3 -45 7 0 21 44 133 50 126 4 -4 15 -30 24 -57z"/>
<path d="M4470 878 c-84 -24 -124 -86 -96 -151 16 -40 35 -52 125 -82 71 -23
93 -44 72 -69 -18 -21 -93 -21 -138 1 -34 16 -35 15 -60 -9 l-25 -25 34 -26
c63 -48 170 -54 233 -13 40 27 60 81 46 128 -14 45 -37 62 -128 93 -58 19 -68
26 -68 46 0 21 5 24 43 27 28 2 53 -3 69 -13 24 -16 26 -16 51 8 29 30 23 45
-31 70 -47 22 -87 27 -127 15z"/>
<path d="M1123 868 c-14 -19 -135 -340 -137 -363 -1 -16 5 -20 33 -20 35 0 43
8 61 58 9 26 12 27 84 27 l74 0 7 -30 c4 -17 13 -37 22 -45 15 -16 68 -21 77
-7 6 10 -116 333 -139 368 -17 26 -65 33 -82 12z m66 -154 c11 -31 21 -60 21
-65 0 -5 -20 -9 -45 -9 -31 0 -45 4 -45 13 0 25 31 117 39 117 5 0 18 -25 30
-56z"/>
<path d="M1499 874 c-30 -9 -39 -52 -39 -194 0 -161 8 -187 57 -196 40 -8 114
3 165 23 74 30 116 112 104 206 -12 103 -75 154 -197 162 -41 2 -81 2 -90 -1z
m182 -113 c25 -25 29 -37 29 -81 0 -44 -4 -56 -29 -81 -25 -24 -37 -29 -80
-29 l-51 0 0 110 0 110 51 0 c43 0 55 -5 80 -29z"/>
<path d="M1927 874 c-4 -4 -7 -92 -7 -195 l0 -189 25 -6 c55 -14 56 -11 53
198 l-3 193 -30 3 c-17 2 -34 0 -38 -4z"/>
<path d="M2610 872 c-35 -11 -40 -34 -40 -192 0 -196 0 -195 102 -194 128 2
193 46 218 146 24 96 -17 190 -97 223 -37 16 -154 27 -183 17z m181 -111 c23
-23 29 -38 29 -71 0 -80 -38 -119 -117 -120 l-53 0 0 110 0 110 56 0 c48 0 59
-4 85 -29z"/>
<path d="M3474 866 c-3 -8 -4 -27 -2 -43 3 -26 6 -28 56 -31 l52 -3 0 -143 c0
-157 5 -170 57 -164 l28 3 3 152 3 152 52 3 52 3 0 40 0 40 -148 3 c-121 2
-148 0 -153 -12z"/>
<path d="M4023 868 c-4 -7 -40 -95 -79 -195 -75 -192 -76 -193 -18 -193 20 0
29 9 45 45 l20 45 70 0 70 0 16 -37 c8 -21 20 -41 27 -45 15 -10 64 -10 69 0
5 8 -96 281 -128 347 -16 32 -26 41 -52 43 -18 2 -35 -2 -40 -10z m65 -159
c12 -31 18 -60 15 -63 -8 -8 -78 -8 -85 0 -3 3 4 34 15 70 12 35 24 61 28 56
3 -4 16 -32 27 -63z"/>
<path d="M4794 866 c-3 -9 -4 -75 -2 -149 3 -147 12 -171 71 -207 43 -26 131
-27 173 -1 51 31 67 80 72 219 3 88 1 126 -8 137 -17 20 -66 19 -74 -1 -3 -9
-6 -71 -6 -138 0 -139 -8 -156 -72 -156 -56 0 -62 16 -68 169 l-5 136 -38 3
c-26 2 -39 -1 -43 -12z"/>
<path d="M5273 865 c-22 -16 -23 -21 -23 -184 0 -145 2 -170 17 -182 25 -21
189 -18 230 4 63 33 82 113 39 164 -24 28 -24 29 -5 52 23 29 24 64 2 101 -23
40 -55 52 -153 57 -68 4 -89 1 -107 -12z m182 -105 c0 -24 -3 -25 -62 -28
l-63 -3 0 31 0 31 63 -3 c59 -3 62 -4 62 -28z m7 -118 c5 -5 8 -22 6 -38 -3
-28 -4 -29 -70 -32 l-68 -3 0 40 c0 33 4 40 23 43 34 6 99 0 109 -10z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
